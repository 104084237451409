// @autogenerated

// WARNING THIS FILE IS AUTOGENERATED! DO NOT EDIT!

// Developer note: these files are treated as templates and called from prebuild.js
// They shouldn't be imported and used directly.

import React from 'react';
import { Link } from 'gatsby';
import onChangeGenerator from '../../commons/onChangeGenerator';
import P from '@govtnz/ds/build/react-ts/P';
import '../../commons/styles/ds/themed-P.scss';
import H2 from '@govtnz/ds/build/react-ts/H2';
import '../../commons/styles/ds/themed-H2.scss';
import Ul from '@govtnz/ds/build/react-ts/Ul';
import '../../commons/styles/ds/themed-Ul.scss';
import Li from '@govtnz/ds/build/react-ts/Li';
import '../../commons/styles/ds/themed-Li.scss';
import A from '@govtnz/ds/build/react-ts/A';
import '../../commons/styles/ds/themed-A.scss';
import components__FlexGrid from '../../commons/examples/components__FlexGrid';
// Indirect relative import because this template is output to src/pages/components so it needs to step back to `commons`.
import ComponentPage from '../../commons/component-page';
import ComponentCode from '../../commons/component-code';
import Example from '../../commons/Example';
import ExampleContainer from '../../commons/ExampleContainer';
import ExampleHeading from '../../commons/ExampleHeading';
import ExampleSection from '../../commons/ExampleSection';
import MainNavMobileMenuContext from '../../commons/MainNavMobileMenuContext';
import '../../commons/styles/ds/themed-Button.scss';
import '../../commons/styles/ds/themed-CaptionL.scss';

const PageContent = (props) => <React.Fragment><P styleSize="large">Use our flexible grid to structure website content. The grid is mobile-first, powered by Flexbox, and based on a 12-column system. The grid includes gutters, column spanning, 4 breakpoints, and column alignment.</P>

<H2 styleSize="large" id="how-it-works">How it works</H2>
<P>The grid system uses a series of containers, rows, and columns to lay out and align content.</P>
<P>The following layout (non-responsive) example creates three equal-width columns on tablet, desktop, and widescreen devices using our predefined grid classes. Those columns are centered in the page with the parent flex container.</P>
<ExampleContainer>
    <ExampleHeading>3-column Flexbox grid</ExampleHeading>
    <Example  code={components__FlexGrid[0]} iframeProps={{
        id:"iframe_componentsFlexGrid0",
        className: "example__iframe",
        src:"/components/FlexGrid__example0.html",
        title:"Example: 3-column Flexbox grid",
        height: 200
      }}></Example>
</ExampleContainer>

<h3 id="containers">Containers</h3>
<P>Flex container width can be fixed or fluid. The fixed container fills the available width until it reaches the maximum of 1366 pixels.</P>
<P>The fluid container has no constraints and fills all of the available space. This is ideal for display uses, such as widescreen HD display.</P>
<h3 id="rows">Rows</h3>
<P>All columns must have a flex row as a parent.</P>
<h3 id="columns">Columns</h3>
<P>Our 12-column grid has gutters, column spanning, four breakpoints, and column alignment.</P>
<P>The &quot;md&quot; in the above example refers to the breakpoint size. Here&#39;s the full range of breakpoint options:</P>
<Ul>
<Li>xs = minimum width of 0 pixels</Li>
<Li>sm = minimum width of 768 pixels</Li>
<Li>md = minimum width of 1024 pixels</Li>
<Li>lg = minimum width of 1200 pixels</Li>
</Ul>
<P>We also support offsets to allow flexibility of design, essentially allowing blank columns to assist aesthetics in layout.</P>
<P>The following example shows all the possible variants of column widths.</P>
<ExampleContainer>
    <ExampleHeading>Flexgrid column variants</ExampleHeading>
    <Example  code={components__FlexGrid[1]} iframeProps={{
        id:"iframe_componentsFlexGrid1",
        className: "example__iframe",
        src:"/components/FlexGrid__example1.html",
        title:"Example: Flexgrid column variants (Flexbox grid)",
        height: 200
      }}></Example>
</ExampleContainer>

<h2 id="why-use-a-flexbox-grid">Why use a Flexbox grid?</h2>
<P>We chose Flexbox because it&#39;s implemented by our supported browsers. We have labelled this grid type &quot;Flex&quot; so that we can add other grid types later. We&#39;re considering adding support for CSS Grid.</P>
<h2 id="credit">Credit</h2>
<P>Original HTML and CSS derived from <A className="g-link"  href="http://flexboxgrid.com/">FlexboxGrid</A> version 6.3.2. Licensed under <A className="g-link"  href="https://github.com/kristoferjoseph/flexboxgrid">the Apache Licence version 2.0</A>.</P>
</React.Fragment>

export default function Code(props) {
  return (
    <ComponentPage
      title={"FlexGrid"}
      id={"FlexGrid"}
      pageProps={props}
      PageContent={PageContent}
    />
  );
}
